import React from 'react';

const Baloon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1417.219"
    height="715.74"
    viewBox="0 0 1417.219 715.74"
    id="main_visual"
    className="mv_animation"
  >
    <defs>
      <filter
        id="合体_1-2"
        x="940.899"
        y="0"
        width="75.999"
        height="103.999"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-2" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="_"
        x="953.348"
        y="8.706"
        width="51"
        height="60"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-3" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-3"
        x="950.612"
        y="121.051"
        width="50"
        height="66"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-4" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="_2"
        x="957"
        y="123"
        width="41"
        height="47"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-5" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-4"
        x="688"
        y="114.008"
        width="58"
        height="77"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-6" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-6" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="_3"
        x="695"
        y="119.957"
        width="43"
        height="50"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-7" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-7" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-5"
        x="860.128"
        y="104.008"
        width="50"
        height="66"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-8" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-8" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="_4"
        x="866.128"
        y="107.158"
        width="41"
        height="47"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-9" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-9" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-6"
        x="1061"
        y="8.999"
        width="50"
        height="66.001"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-10" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-10" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-7"
        x="1046.096"
        y="106.24"
        width="76.667"
        height="104.989"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-11" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-11" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-8"
        x="1116.764"
        y="56.999"
        width="67"
        height="90"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-12" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-12" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-9"
        x="949.612"
        y="175.008"
        width="50.368"
        height="66.552"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-13" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-13" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-10"
        x="795.139"
        y="286.426"
        width="58.46"
        height="77.679"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-14" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-14" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-11"
        x="905.496"
        y="359.001"
        width="53.403"
        height="70.598"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-15" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-15" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-12"
        x="1027.333"
        y="324.611"
        width="76.667"
        height="104.989"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-16" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-16" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-13"
        x="994.164"
        y="172.184"
        width="53.403"
        height="70.598"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-17" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-17" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-14"
        x="1093"
        y="372.793"
        width="58.46"
        height="77.679"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-18" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-18" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-15"
        x="1104.763"
        y="291.227"
        width="76.667"
        height="104.989"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-19" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-19" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-16"
        x="860.128"
        y="249.215"
        width="50.368"
        height="66.552"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-20" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-20" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-17"
        x="618"
        y="86"
        width="75.999"
        height="103.999"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-21" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-21" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-18"
        x="648.001"
        y="308"
        width="58"
        height="77"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-22" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-22" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-19"
        x="516"
        y="243.226"
        width="50"
        height="66"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-23" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-23" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-20"
        x="423.533"
        y="348.792"
        width="50"
        height="66"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-24" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-24" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-21"
        x="121.357"
        y="494.784"
        width="50"
        height="66"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-25" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-25" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-22"
        x="68.216"
        y="496.477"
        width="53"
        height="70"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-26" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-26" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_2"
        x="385.688"
        y="480.487"
        width="50"
        height="66"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-27" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-27" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-23"
        x="559.001"
        y="505.387"
        width="58"
        height="77"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-28" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-28" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-24"
        x="580"
        y="378"
        width="58"
        height="77"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-29" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-29" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-25"
        x="638.002"
        y="388.501"
        width="75.999"
        height="103.999"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-30" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-30" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="_5"
        x="651.002"
        y="396.252"
        width="51"
        height="60"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-31" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-31" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_1-26"
        x="316.001"
        y="329.502"
        width="58"
        height="77"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur-32" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-32" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="グループ_3011"
      data-name="グループ 3011"
      transform="translate(-113 6)"
    >
      <g id="balloon" transform="translate(-68 -84)">
        <g className="balloon_anim balloonGroup3" id="balloon25">
          <g data-name="グループ 1001" transform="translate(114.899 -68)">
            <g
              transform="matrix(1, 0, 0, 1, 66.1, 146)"
              filter="url(#合体_1-2)"
            >
              <path
                id="合体_1-28"
                data-name="合体 1"
                d="M-13139.3,57.516A29.007,29.007,0,0,1-13163,29a29,29,0,0,1,29-29,29,29,0,0,1,29,29,29.006,29.006,0,0,1-23.7,28.515L-13134,86Z"
                transform="translate(14112.9 6)"
                fill="#10a8eb"
              />
            </g>
            <g transform="matrix(1, 0, 0, 1, 66.1, 146)" filter="url(#_)">
              <text
                id="_7"
                data-name="🤩"
                transform="translate(962.35 46.71)"
                fill="#10a8eb"
                fontSize="32"
                fontFamily="AppleColorEmoji, Apple Color Emoji"
              >
                <tspan x="0" y="0">
                  🤩
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g className="balloon_anim balloonGroup0" id="balloon22">
          <g
            id="グループ_2964"
            data-name="グループ 2964"
            transform="translate(25 -33.949)"
          >
            <g
              id="グループ_1179"
              data-name="グループ 1179"
              transform="translate(99.612 88.752)"
            >
              <g
                transform="matrix(1, 0, 0, 1, 56.39, 23.2)"
                filter="url(#合体_1-3)"
              >
                <path
                  id="合体_1-29"
                  data-name="合体 1"
                  d="M13.077,32.1A16.14,16.14,0,0,1,0,16.187,16.094,16.094,0,0,1,16,0,16.093,16.093,0,0,1,32,16.187,16.14,16.14,0,0,1,18.924,32.1L16,48Z"
                  transform="translate(959.61 127.05)"
                  fill="#10a8eb"
                />
              </g>
            </g>
            <g transform="matrix(1, 0, 0, 1, 156, 111.95)" filter="url(#_2)">
              <text
                id="_8"
                data-name="😀"
                transform="translate(966 151)"
                fill="#10a8eb"
                fontSize="20"
                fontFamily="AppleColorEmoji, Apple Color Emoji"
              >
                <tspan x="0" y="0">
                  😀
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g className="balloon_anim balloonGroup4" id="balloon15">
          <g
            id="グループ_1180"
            data-name="グループ 1180"
            transform="translate(-138 46.604)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 319, 31.4)"
              filter="url(#合体_1-4)"
            >
              <path
                id="合体_1-30"
                data-name="合体 1"
                d="M16.346,39.459a20,20,0,1,1,7.309,0L20,59Z"
                transform="translate(697 120.01)"
                fill="#10a8eb"
              />
            </g>
            <g transform="matrix(1, 0, 0, 1, 319, 31.4)" filter="url(#_3)">
              <text
                id="_9"
                data-name="😉"
                transform="translate(704 149.96)"
                fill="#10a8eb"
                fontSize="24"
                fontFamily="AppleColorEmoji, Apple Color Emoji"
              >
                <tspan x="0" y="0">
                  😉
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g className="balloon_anim balloonGroup4" id="balloon21">
          <g
            id="グループ_1269"
            data-name="グループ 1269"
            transform="translate(34.127 37.761)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 146.87, 40.24)"
              filter="url(#合体_1-5)"
            >
              <path
                id="合体_1-31"
                data-name="合体 1"
                d="M13.077,32.1A16.14,16.14,0,0,1,0,16.187,16.094,16.094,0,0,1,16,0,16.093,16.093,0,0,1,32,16.187,16.14,16.14,0,0,1,18.924,32.1L16,48Z"
                transform="translate(869.13 110.01)"
                fill="#10a8eb"
              />
            </g>
            <g
              transform="matrix(1, 0, 0, 1, 146.87, 40.24)"
              filter="url(#_4)"
            >
              <text
                id="_10"
                data-name="❤️"
                transform="translate(875.13 135.16)"
                fill="#10a8eb"
                fontSize="20"
                fontFamily="AppleColorEmoji, Apple Color Emoji"
              >
                <tspan x="0" y="0">
                  ❤️
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g className="balloon_anim balloonGroup1" id="balloon26">
          <g
            id="グループ_1264"
            data-name="グループ 1264"
            transform="translate(235 -57.248)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -54, 135.25)"
              filter="url(#合体_1-6)"
            >
              <path
                id="合体_1-32"
                data-name="合体 1"
                d="M13.077,32.1A16.2,16.2,0,0,1,16,0,16.094,16.094,0,0,1,32,16.187,16.14,16.14,0,0,1,18.924,32.1L16,48Z"
                transform="translate(1070 15)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_11"
              data-name="😎"
              transform="translate(1021.687 175.75)"
              fill="#707070"
              fontSize="20"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="0" y="0">
                😎
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup2" id="balloon23">
          <g
            id="グループ_1004"
            data-name="グループ 1004"
            transform="translate(1236.096 187.206)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -1055.1, -109.21)"
              filter="url(#合体_1-7)"
            >
              <path
                id="合体_1-33"
                data-name="合体 1"
                d="M23.974,58.178a29.333,29.333,0,1,1,10.72,0L29.335,86.989Z"
                transform="translate(1055.1 112.24)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_12"
              data-name="😉"
              transform="translate(29.092 49)"
              fill="#fff"
              fontSize="42"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-21" y="0">
                😉
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup0" id="balloon24">
          <g
            id="グループ_1003"
            data-name="グループ 1003"
            transform="translate(290.765 -11.001)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -109.77, 89)"
              filter="url(#合体_1-8)"
            >
              <path
                id="合体_1-34"
                data-name="合体 1"
                d="M20.023,48.153A24.264,24.264,0,0,1,24.5,0a24.264,24.264,0,0,1,4.477,48.153L24.5,72Z"
                transform="translate(1125.77 63)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_13"
              data-name="☺️"
              transform="translate(1040 190.001)"
              fill="#fff"
              fontSize="35"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-17.5" y="0">
                ☺️
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup1" id="balloon19">
          <g
            id="グループ_1005"
            data-name="グループ 1005"
            transform="translate(1139.612 257.997)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -958.61, -180)"
              filter="url(#合体_1-9)"
            >
              <path
                id="合体_1-35"
                data-name="合体 1"
                d="M13.227,32.472A16.326,16.326,0,0,1,0,16.373,16.279,16.279,0,0,1,16.185,0,16.278,16.278,0,0,1,32.368,16.373a16.326,16.326,0,0,1-13.227,16.1L16.185,48.552Z"
                transform="translate(958.61 181.01)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_14"
              data-name="🤩"
              transform="translate(17.058 27)"
              fill="#fff"
              fontSize="20"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-11.5" y="0">
                🤩
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup0" id="balloon11">
          <g
            id="グループ_1006"
            data-name="グループ 1006"
            transform="translate(985.139 368.404)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -804.14, -290.4)"
              filter="url(#合体_1-10)"
            >
              <path
                id="合体_1-36"
                data-name="合体 1"
                d="M16.534,39.913a20.23,20.23,0,1,1,7.393,0l-3.7,19.766Z"
                transform="translate(804.14 292.43)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_15"
              data-name="🤩"
              transform="translate(20.069 34)"
              fill="#fff"
              fontSize="24"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-12.5" y="0">
                🤩
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup1" id="balloon12">
          <g
            id="グループ_1007"
            data-name="グループ 1007"
            transform="translate(1095.496 441.99)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -914.5, -363.99)"
              filter="url(#合体_1-11)"
            >
              <path
                id="合体_1-37"
                data-name="合体 1"
                d="M14.467,35.178a17.7,17.7,0,1,1,6.469,0L17.7,52.6Z"
                transform="translate(914.5 365)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_16"
              data-name="❤️"
              transform="translate(19.058 28)"
              fill="#fff"
              fontSize="20"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-11.5" y="0">
                ❤️
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup4" id="balloon16">
          <g
            id="グループ_1010"
            data-name="グループ 1010"
            transform="translate(1217.333 405.577)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -1036.33, -327.58)"
              filter="url(#合体_1-12)"
            >
              <path
                id="合体_1-38"
                data-name="合体 1"
                d="M23.974,58.178a29.333,29.333,0,1,1,10.72,0L29.335,86.989Z"
                transform="translate(1036.33 330.61)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_17"
              data-name="😙"
              transform="translate(29.092 49)"
              fill="#fff"
              fontSize="42"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-21" y="0">
                😙
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup3" id="balloon20">
          <g
            id="グループ_1557"
            data-name="グループ 1557"
            transform="translate(1038.365 263.783)"
          >
            <g
              id="グループ_1558"
              data-name="グループ 1558"
              transform="translate(145.799 -8.61)"
            >
              <g
                transform="matrix(1, 0, 0, 1, -1003.16, -177.17)"
                filter="url(#合体_1-13)"
              >
                <path
                  id="合体_1-39"
                  data-name="合体 1"
                  d="M14.467,35.178a17.7,17.7,0,1,1,6.469,0L17.7,52.6Z"
                  transform="translate(1003.16 178.18)"
                  fill="#10a8eb"
                />
              </g>
              <text
                id="_18"
                data-name="😍"
                transform="translate(19.058 27)"
                fill="#fff"
                fontSize="20"
                fontFamily="AppleColorEmoji, Apple Color Emoji"
              >
                <tspan x="-11.5" y="0">
                  😍
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g className="balloon_anim balloonGroup0" id="balloon17">
          <g
            id="グループ_2951"
            data-name="グループ 2951"
            transform="translate(1283 454.77)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -1102, -376.77)"
              filter="url(#合体_1-14)"
            >
              <path
                id="合体_1-40"
                data-name="合体 1"
                d="M16.534,39.913a20.23,20.23,0,1,1,7.393,0l-3.7,19.766Z"
                transform="translate(1102 378.79)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_19"
              data-name="😎"
              transform="translate(21.069 32)"
              fill="#fff"
              fontSize="24"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-12.5" y="0">
                😎
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup2" id="balloon18">
          <g
            id="グループ_2770"
            data-name="グループ 2770"
            transform="translate(1294.763 372.193)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -1113.76, -294.19)"
              filter="url(#合体_1-15)"
            >
              <path
                id="合体_1-41"
                data-name="合体 1"
                d="M23.974,58.178a29.333,29.333,0,1,1,10.72,0L29.335,86.989Z"
                transform="translate(1113.76 297.23)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_20"
              data-name="☺️"
              transform="translate(29.092 49)"
              fill="#fff"
              fontSize="42"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-21" y="0">
                ☺️
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup3" id="balloon14">
          <g
            id="グループ_1262"
            data-name="グループ 1262"
            transform="translate(1050.127 332.204)"
          >
            <g
              transform="matrix(1, 0, 0, 1, -869.13, -254.2)"
              filter="url(#合体_1-16)"
            >
              <path
                id="合体_1-42"
                data-name="合体 1"
                d="M13.227,32.472A16.326,16.326,0,0,1,0,16.373,16.279,16.279,0,0,1,16.185,0,16.278,16.278,0,0,1,32.368,16.373a16.326,16.326,0,0,1-13.227,16.1L16.185,48.552Z"
                transform="translate(869.13 255.22)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_21"
              data-name="😙"
              transform="translate(17.058 26)"
              fill="#fff"
              fontSize="20"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-11.5" y="0">
                😙
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup4" id="balloon13">
          <g
            id="グループ_1074"
            data-name="グループ 1074"
            transform="translate(-208 18)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 389, 60)"
              filter="url(#合体_1-17)"
            >
              <path
                id="合体_1-43"
                data-name="合体 1"
                d="M-13139.3,57.516A29.007,29.007,0,0,1-13163,29a29,29,0,0,1,29-29,29,29,0,0,1,29,29,29.006,29.006,0,0,1-23.7,28.515L-13134,86Z"
                transform="translate(13790 92)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_22"
              data-name="😀"
              transform="translate(1045 198)"
              fill="#fff"
              fontSize="42"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-21" y="0">
                😀
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup2" id="balloon10">
          <g
            id="グループ_2965"
            data-name="グループ 2965"
            transform="translate(66 -37)"
          >
            <g
              id="グループ_1075"
              data-name="グループ 1075"
              transform="translate(-244 277.62)"
            >
              <g
                transform="matrix(1, 0, 0, 1, 359, -162.62)"
                filter="url(#合体_1-18)"
              >
                <path
                  id="合体_1-44"
                  data-name="合体 1"
                  d="M16.346,39.459a20,20,0,1,1,7.309,0L20,59Z"
                  transform="translate(657 314)"
                  fill="#10a8eb"
                />
              </g>
            </g>
            <text
              id="_23"
              data-name="😎"
              transform="translate(779 459)"
              fill="#707070"
              fontSize="26"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="0" y="0">
                😎
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup3" id="balloon9">
          <g
            id="グループ_2966"
            data-name="グループ 2966"
            transform="translate(-310 176.979)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 491, -98.98)"
              filter="url(#合体_1-19)"
            >
              <path
                id="合体_1-45"
                data-name="合体 1"
                d="M13.077,32.1A16.14,16.14,0,0,1,0,16.187,16.094,16.094,0,0,1,16,0,16.093,16.093,0,0,1,32,16.187,16.14,16.14,0,0,1,18.924,32.1L16,48Z"
                transform="translate(525 249.23)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_24"
              data-name="😉"
              transform="translate(1033 174.248)"
              fill="#fff"
              fontSize="20"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-11.5" y="0">
                😉
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup1" id="balloon6">
          <g
            id="グループ_2967"
            data-name="グループ 2967"
            transform="translate(-402.467 282.545)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 583.47, -204.54)"
              filter="url(#合体_1-20)"
            >
              <path
                id="合体_1-46"
                data-name="合体 1"
                d="M13.077,32.1A16.14,16.14,0,0,1,0,16.187,16.094,16.094,0,0,1,16,0,16.093,16.093,0,0,1,32,16.187,16.14,16.14,0,0,1,18.924,32.1L16,48Z"
                transform="translate(432.53 354.79)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_25"
              data-name="☺️"
              transform="translate(1022.313 175.248)"
              fill="#707070"
              fontSize="20"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="0" y="0">
                ☺️
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup2" id="balloon2">
          <g
            id="グループ_2968"
            data-name="グループ 2968"
            transform="translate(-704.644 428.537)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 885.64, -350.54)"
              filter="url(#合体_1-21)"
            >
              <path
                id="合体_1-47"
                data-name="合体 1"
                d="M13.077,32.1A16.14,16.14,0,0,1,0,16.187,16.094,16.094,0,0,1,16,0,16.093,16.093,0,0,1,32,16.187,16.14,16.14,0,0,1,18.924,32.1L16,48Z"
                transform="translate(130.36 500.78)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_26"
              data-name="☺️"
              transform="translate(1034 174.248)"
              fill="#fff"
              fontSize="20"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-11.5" y="0">
                ☺️
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim" id="balloon1">
          <g
            id="グループ_2969"
            data-name="グループ 2969"
            transform="translate(-757.783 430.102)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 938.78, -352.1)"
              filter="url(#合体_1-22)"
            >
              <path
                id="合体_1-48"
                data-name="合体 1"
                d="M14.3,34.777a17.5,17.5,0,1,1,6.4,0L17.5,52Z"
                transform="translate(77.22 502.48)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_27"
              data-name="🤩"
              transform="translate(1022.783 176.477)"
              fill="#707070"
              fontSize="20"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="0" y="0">
                🤩
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup0" id="balloon3">
          <g
            id="グループ_2970"
            data-name="グループ 2970"
            transform="translate(102.688 33.059)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 78.31, 44.94)"
              filter="url(#合体_2)"
            >
              <path
                id="合体_2-2"
                data-name="合体 2"
                d="M13.077,32.1A16.14,16.14,0,0,1,0,16.187,16.094,16.094,0,0,1,16,0,16.093,16.093,0,0,1,32,16.187,16.14,16.14,0,0,1,18.924,32.1L16,48Z"
                transform="translate(394.69 486.49)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_28"
              data-name="☺️"
              transform="translate(491 555.429)"
              fill="#fff"
              fontSize="20"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-11.5" y="0">
                ☺️
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup0" id="balloon5">
          <g
            id="グループ_2971"
            data-name="グループ 2971"
            transform="translate(-267 438.007)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 448, -360.01)"
              filter="url(#合体_1-23)"
            >
              <path
                id="合体_1-49"
                data-name="合体 1"
                d="M16.346,39.459a20,20,0,1,1,7.309,0L20,59Z"
                transform="translate(568 511.39)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_29"
              data-name="🤩"
              transform="translate(1036 181.38)"
              fill="#fff"
              fontSize="24"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-12.5" y="0">
                🤩
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup2" id="balloon7">
          <g
            id="グループ_2972"
            data-name="グループ 2972"
            transform="translate(-246 310.62)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 427, -232.62)"
              filter="url(#合体_1-24)"
            >
              <path
                id="合体_1-50"
                data-name="合体 1"
                d="M16.346,39.459a20,20,0,1,1,7.309,0L20,59Z"
                transform="translate(589 384)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_30"
              data-name="❤️"
              transform="translate(1036 181.38)"
              fill="#fff"
              fontSize="22"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-12" y="0">
                ❤️
              </tspan>
            </text>
          </g>
        </g>
        <g className="balloon_anim balloonGroup4" id="balloon8">
          <g
            id="グループ_2973"
            data-name="グループ 2973"
            transform="translate(-187.999 320.502)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 369, -242.5)"
              filter="url(#合体_1-25)"
            >
              <path
                id="合体_1-51"
                data-name="合体 1"
                d="M-13139.3,57.516A29.007,29.007,0,0,1-13163,29a29,29,0,0,1,29-29,29,29,0,0,1,29,29,29.006,29.006,0,0,1-23.7,28.515L-13134,86Z"
                transform="translate(13810 394.5)"
                fill="#10a8eb"
              />
            </g>
            <g transform="matrix(1, 0, 0, 1, 369, -242.5)" filter="url(#_5)">
              <text
                id="_31"
                data-name="😍"
                transform="translate(660 434.25)"
                fill="#10a8eb"
                fontSize="32"
                fontFamily="AppleColorEmoji, Apple Color Emoji"
              >
                <tspan x="0" y="0">
                  😍
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g className="balloon_anim balloonGroup3" id="balloon4">
          <g
            id="グループ_2982"
            data-name="グループ 2982"
            transform="translate(-510 262.122)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 691, -184.12)"
              filter="url(#合体_1-26)"
            >
              <path
                id="合体_1-52"
                data-name="合体 1"
                d="M16.346,39.459a20,20,0,1,1,7.309,0L20,59Z"
                transform="translate(325 335.5)"
                fill="#10a8eb"
              />
            </g>
            <text
              id="_32"
              data-name="🤩"
              transform="translate(1036 181.38)"
              fill="#fff"
              fontSize="24"
              fontFamily="AppleColorEmoji, Apple Color Emoji"
            >
              <tspan x="-12.5" y="0">
                🤩
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Baloon;
