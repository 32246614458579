import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import AnimationWrapper from '../animation-wrapper';
import SpeakerDeck from '../speeker-deck';

import * as Styles from '../../styles/top/_about.module.scss';
import SectionTitle from '../section-title';

const About = () => (
  <section className={Styles.about}>
    <div className="container">
      <AnimationWrapper>
        <SectionTitle>about</SectionTitle>
        <h2 className="section-title-ja">カミナシについて</h2>
        <div className={Styles.about__deck_container}>
          <SpeakerDeck id="8cd0451bf2114ce68fab034a99075876" />
        </div>
      </AnimationWrapper>

      <div className={Styles.about__chara}>
        <StaticImage src="../../images/about_goton.png" alt="ごーとん" />
      </div>
    </div>
  </section>
);

export default About;
