// extracted by mini-css-extract-plugin
export var button = "_member-module--button--2c7b3";
export var member = "_member-module--member--e0181";
export var member__animation = "_member-module--member__animation--3f787";
export var member__box = "_member-module--member__box--0714a";
export var member__box_grid = "_member-module--member__box_grid--e8941";
export var member__boxicon = "_member-module--member__boxicon--bc581";
export var member__boxicon__twitter = "_member-module--member__boxicon__twitter--6e88d";
export var member__boxself = "_member-module--member__boxself--81902";
export var member__boxtext = "_member-module--member__boxtext--ab36b";
export var member__col = "_member-module--member__col--8843b";
export var member__container = "_member-module--member__container--b527d";
export var member__image = "_member-module--member__image--00f90";
export var member__name = "_member-module--member__name--383bb";
export var member__post = "_member-module--member__post--399fd";
export var member__row = "_member-module--member__row--b7965";
export var member__title = "_member-module--member__title--3bfdc";