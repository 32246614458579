import React from 'react';

const SpeakerDeck = ({ id }) => (
  <div
    style={{
      left: 0,
      width: '100%',
      height: 0,
      position: 'relative',
      paddingBottom: '56.1987%',
      marginBottom: '2rem',
    }}
  >
    {/* FIXME: SecurityError: Blocked a frame with origin from accessing a cross-origin frame */}
    <iframe
      src={`//speakerdeck.com/player/${id}`}
      title={`speakerdeck_${id}`}
      style={{
        border: 0,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
      }}
      frameBorder="0"
      allowFullScreen
      loading="lazy"
    />
  </div>
);

export default SpeakerDeck;
