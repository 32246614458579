import React from 'react';
import * as Styles from '../styles/top/_member.module.scss';

import NoreferrerLink from './noreferrerLink';

import ICONtw from '../../static/twitter_logo.svg';
import ICONnt from '../../static/note_icon.svg';
import ICONha from '../../static/hatena_icon.svg';
import ICONgh from '../../static/github_icon.svg';
import ICONwl from '../../static/wantedly_icon.svg';

const MemberCard = ({ item }) => (
  <div key={item.memberId} className={Styles.member__col}>
    <div className={Styles.member__box}>
      <div className={Styles.member__box_grid}>
        <img
          src={`${item.thumbnail.url}?fit=crop&w=220&h=220`}
          alt={`${item.firstName} ${item.secondName}`}
          width="110"
          height="110"
          className={Styles.member__image}
        />

        <div className={Styles.member__boxself}>
          <p className={Styles.member__name}>
            {item.firstName}
            {' '}
            {item.secondName}
          </p>
          <p className={Styles.member__post}>{item.job}</p>
        </div>

        <div className={Styles.member__boxicon}>
          {item.twitterUrl && (
            <NoreferrerLink to={item.twitterUrl} className={Styles.member__boxicon__twitter}>
              <img src={ICONtw} alt="twitter_logo" />
            </NoreferrerLink>
          )}
          {item.noteUrl && (
            <NoreferrerLink to={item.noteUrl}>
              <img src={ICONnt} alt="facebook_logo" />
            </NoreferrerLink>
          )}
          {item.wantedlyUrl && (
            <NoreferrerLink to={item.wantedlyUrl}>
              <img src={ICONwl} alt="wantedly_logo" />
            </NoreferrerLink>
          )}
          {item.githubUrl && (
            <NoreferrerLink to={item.githubUrl}>
              <img src={ICONgh} alt="github_logo" />
            </NoreferrerLink>
          )}
          {item.hatenaUrl && (
            <NoreferrerLink to={item.hatenaUrl}>
              <img src={ICONha} alt="hatena_logo" />
            </NoreferrerLink>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default MemberCard;
