import React from 'react';
import classNames from 'classnames';

import { StaticImage } from 'gatsby-plugin-image';

import AnimationWrapper from '../animation-wrapper';
import SectionTitle from '../section-title';
import Button from '../button';
import EventCard from '../event-card';

import * as Styles from '../../styles/top/_event.module.scss';

const Event = ({ list }) => (
  <section className={Styles.event}>
    <div className={classNames('container', Styles.event__container)}>
      <AnimationWrapper>
        <SectionTitle>event</SectionTitle>
        <h2 className="section-title-ja">イベント</h2>
      </AnimationWrapper>

      <div className={Styles.inner_position}>
        <ul>
          {list.map((item) => (
            <li key={item.node.eventId} className="col_4">
              <AnimationWrapper>
                <EventCard {...item.node} />
              </AnimationWrapper>
            </li>
          ))}
        </ul>

        <div className={classNames('text-center', Styles.button)}>
          <AnimationWrapper>
            <Button to="/event/">イベント一覧を見る</Button>
          </AnimationWrapper>
        </div>

        <div className={Styles.event__chara}>
          <StaticImage src="../../images/sales_goton.png" alt="ごーとん" />
        </div>
      </div>
    </div>
  </section>
);

export default Event;
