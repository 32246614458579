import React from 'react';
import classNames from 'classnames';

import SectionTitle from '../../section-title';
import { RecruitCard } from './component';
import Button from '../../button';

import * as Styles from '../../../styles/top/_recruit.module.scss';

const Recruit = ({ list }) => (
  <section className={Styles.contents}>
    <div className="container">
      <SectionTitle>recruit</SectionTitle>
      <h2 className="section-title-ja">採用情報</h2>
      <div className="row">
        {list.map((item) => (
          <div key={item.node.title} className={classNames(Styles.col, 'col_4')}>
            <RecruitCard item={item.node} />
          </div>
        ))}
      </div>

      <div className={classNames(Styles.button, 'text-center')}>
        <Button to="https://herp.careers/v1/kaminashi" yellow blank>
          全ポジション一覧を見る
        </Button>
      </div>
    </div>
  </section>
);

export default Recruit;
