import React from 'react';
import classNames from 'classnames';
import * as Styles from '../../styles/top/_photo_gallery.module.scss';

import AnimationWrapper from '../animation-wrapper';
import SectionTitle from '../section-title';

const PhotoGallery = ({ list }) => (
  <div className={Styles.container}>
    <AnimationWrapper>
      <SectionTitle>photo gallery</SectionTitle>
      <h2 className="section-title-ja">フォトギャラリー</h2>
    </AnimationWrapper>
    <div className="container">
      <div className={classNames('row', Styles.row)}>
        {list.map((item) => (
          <div
            key={item.node.photoGalleryId}
            className={classNames('col', 'col_4', Styles.card, Styles.col)}
          >
            <AnimationWrapper>
              <div className="img_wrap">
                <picture>
                  <source
                    className="cast-image"
                    type="image/webp"
                    src={`${item.node.thumbnail.url}?fm=webp&fit=crop&w=746&h=563`}
                    width="373"
                    height="282"
                    loading="lazy"
                    alt={item.node.caption}
                  />
                  <img
                    className="cast-image"
                    src={`${item.node.thumbnail.url}?fm=jpg&fit=crop&w=746&h=563`}
                    alt={item.node.caption}
                    width="373"
                    height="282"
                    loading="lazy"
                  />
                </picture>
              </div>
              <p>{item.node.caption}</p>
            </AnimationWrapper>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default PhotoGallery;
