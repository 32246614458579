import React from 'react';
import classNames from 'classnames';

import NoreferrerLink from '../noreferrerLink';

import * as Styles from '../../styles/top/_pu_contents.module.scss';

const PickupCard = (props) => {
  const {
    header, body, children, icon, link, postLink, ...rest
  } = props;
  return (
    <div className={Styles.contents__box_wrap} {...rest}>
      <NoreferrerLink className={Styles.contents__title} to={link}>
        <p
          className={classNames(
            Styles.contents__title__text,
            icon && Styles.contents__icon,
            icon && `${icon}__icon`,
          )}
        >
          {header}
        </p>
      </NoreferrerLink>

      <NoreferrerLink className={Styles.contents__body} to={postLink}>
        <div className={Styles.contents__body__inner}>
          <div>{children}</div>
          <p className={Styles.contents__body__inner__text}>{body}</p>
        </div>
      </NoreferrerLink>
    </div>
  );
};

export default PickupCard;
