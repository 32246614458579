import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import AnimationWrapper from '../animation-wrapper';

import * as Styles from '../../styles/top/_kaminashi.module.scss';

const Kaminashi = () => (
  <div className="container">
    <div className={Styles.kaminashi}>
      <div className={Styles.kaminashi__image}>
        <AnimationWrapper>
          <StaticImage
            src="../../images/mock.png"
            alt="Kaminashi"
            width={1700}
          />
        </AnimationWrapper>
      </div>
      <div className={Styles.kaminashi__text}>
        <AnimationWrapper>
          <h2>
            <strong>「カミナシ」</strong>
            で
            <br />
            ノンデスクワーカーの
            <br className="sp-only" />
            働き方を変えていきます。
          </h2>
          <p>
            <span>
              日本の就業人口約6,700万人のうち、デスク以外の現場で働くノンデスクワーカーは3,900万人。
            </span>
            <span>
              世の中はデジタルが当たり前になりつつある一方で、
              <br className="pc-only" />
              ノンデスクワーカーが働く現場にはいまだに紙が溢れ、非効率な働き方が続いています。
              <br />
              つまり、3,900万人のエネルギーは非効率な環境に埋もれてしまっているのです。
            </span>
            <span>
              私たちは、現場DXプラットフォーム「カミナシ」を通じてノンデスクワーカーの働き方を変革し、
              <br />
              彼らの才能を解き放つことを目指します。
            </span>
          </p>
        </AnimationWrapper>
      </div>
    </div>
  </div>
);

export default Kaminashi;
