import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { fadeInUp } from '../animations/variants';

const AnimationWrapper = ({ children, className }) => {
  const controls = useAnimation();

  const [ref, inView] = useInView({
    threshold: [0.25],
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      variants={fadeInUp}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default AnimationWrapper;
