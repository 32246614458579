import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Balloon from './components/balloon';

import * as Styles from '../../../styles/top/_hero.module.scss';

const Hero = () => (
  <div id="hero-area" className={Styles.hero}>
    <div className={Styles.hero__title}>
      <h2>
        今まで誰もやってこなかった、
        <br />
        <span>3,900</span>
        万人の
        <br />
        <strong>
          働き方
          <span>を</span>
          変える
          <span>仕事。</span>
        </strong>
      </h2>
      <p>
        泥臭く、一歩一歩、確実に変えていく。
        <br />
        未開拓のデスクレスSaaSという巨大な領域で
        <br className="pc-only" />
        いっしょに戦いませんか。
      </p>
    </div>

    <div className={Styles.hero__image}>
      <Balloon />

      <div>
        <StaticImage
          src="../../../images/hero-image.png"
          alt="hero"
          quality="85"
        />
      </div>
    </div>
  </div>
);

export default Hero;
