import React from 'react';

import * as Styles from '../styles/_faq_box.module.scss';

const FaqBox = ({ question, answer }) => (
  <details className={Styles.faq_box}>
    <summary>{question}</summary>
    <p>{answer}</p>
  </details>
);

export default FaqBox;
