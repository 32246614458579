import React from 'react';

import SectionTitle from '../section-title';
import FaqBox from '../faq-box';
import AnimationWrapper from '../animation-wrapper';

import * as Styles from '../../styles/top/_faq.module.scss';

const Faq = ({ list, categories }) => {
  const categoryMatch = (category) => {
    const matchArray = [];
    list.forEach((element) => {
      if (element.node.category.id === category) {
        matchArray.push(element.node);
      }
    });
    return matchArray;
  };

  return (
    <section className={Styles.contents}>
      <div className="container">
        <AnimationWrapper>
          <SectionTitle white>FAQ</SectionTitle>
          <h2 className="section-title-ja wh">よくある質問</h2>
        </AnimationWrapper>

        {categories.map((category) => (
          <div key={category.node.faqCategoriesId} className={Styles.faqBox}>
            <AnimationWrapper>
              <h3>{category.node.category}</h3>
            </AnimationWrapper>
            <ul className={Styles.toggle_list}>
              {categoryMatch(category.node.faqCategoriesId).map((item) => (
                <li key={item.faqId}>
                  <AnimationWrapper>
                    <FaqBox question={item.question} answer={item.answer} />
                  </AnimationWrapper>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;
