import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import NoreferrerLink from './noreferrerLink';

import * as Styles from '../styles/_button.module.scss';

const Button = ({
  to, children, className, red, yellow, blank,
}) => (
  <div className={Styles.button}>
    {!blank ? (
      <Link
        to={to}
        className={classNames(
          Styles.button__inner,
          red && Styles.red__inner,
          yellow && Styles.yellow__inner,
          className && className,
        )}
      >
        {children}
      </Link>
    ) : (
      <NoreferrerLink
        to={to}
        className={classNames(
          Styles.button__inner,
          red && Styles.red__inner,
          yellow && Styles.yellow__inner,
          className && className,
        )}
      >
        {children}
      </NoreferrerLink>
    )}
  </div>
);
export default Button;
