import React from 'react';

import NoreferrerLink from '../../../noreferrerLink';

import * as Styles from '../../../../styles/top/_recruit_card.module.scss';

export const RecruitCard = ({ item }) => {
  const { title, text, link } = { ...item };
  return (
    <NoreferrerLink to={link}>
      <div className={Styles.recruit_card}>
        <h3 className={Styles.recruit_card__title}>{title}</h3>
        {text && (
          <p className={Styles.recruit_card__text}>{text}</p>
        )}
      </div>
    </NoreferrerLink>
  );
};
