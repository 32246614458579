import React from 'react';
import classNames from 'classnames';
import * as Styles from '../../styles/top/_member.module.scss';

import SectionTitle from '../section-title';
import AnimationWrapper from '../animation-wrapper';
import MemberCard from '../member-card';
import Button from '../button';

const Member = ({ list }) => {
  const DISPLAY_CARD_NUM = 6;
  const displayMember = list.filter((item) => item.node.isDisplayTopPage)
    .slice(0, DISPLAY_CARD_NUM);
  return (
    <section className={Styles.member}>
      <div className={classNames('container', Styles.member__container)}>
        <AnimationWrapper>
          <SectionTitle>member</SectionTitle>
          <h2 className="section-title-ja">メンバー紹介</h2>
        </AnimationWrapper>
        <AnimationWrapper>
          <div className="container">
            <div className={Styles.member__row}>
              {displayMember.map((item) => (
                <MemberCard key={item.node.memberId} item={item.node} />
              ))}
            </div>
            <div className={classNames('text-center', Styles.button)}>
              <Button to="/member/">メンバー一覧を見る</Button>
            </div>
          </div>
        </AnimationWrapper>
      </div>
    </section>
  );
};

export default Member;
